
html,
body,
#q-app {
    min-height: 100vh !important;
    background-color: #fbfafa !important;
}

div, p, span {
    letter-spacing: v-bind('letterSpacingFonts') !important;
}

body.q-ios-padding .q-dialog__inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

body.q-ios-padding .q-dialog__inner > div {
    max-height: 100vh !important;
}

.q-notification {
    margin: 48px 10px 0;
}

.q-img {
    display: v-bind('settingsCss.images') !important;
}

img {
    display: v-bind('settingsCss.images') !important;
}

// .font-24 {
//     font-size: v-bind('sizeFonts.font24') !important;
// }

// .font-22 {
//     font-size: v-bind('sizeFonts.font22') !important;
// }

// .font-20 {
//     font-size: v-bind('sizeFonts.font20') !important;
// }

// .font-18 {
//     font-size: v-bind('sizeFonts.font18') !important;
// }

// .font-16 {
//     font-size: v-bind('sizeFonts.font16') !important;
// }

// .font-15 {
//     font-size: v-bind('sizeFonts.font15') !important;
// }

// .font-14 {
//     font-size: v-bind('sizeFonts.font14') !important;
// }

// .font-12 {
//     font-size: v-bind('sizeFonts.font12') !important;
// }

// .font-10 {
//     font-size: v-bind('sizeFonts.font10') !important;
// }

